<template>
  <NuxtLink :to="`/series/${series.id || ''}`" class="
    flex-none p-0 md:p-4 rounded-xl md:bg-zinc-900 md:hover:bg-zinc-800 
    will-change-[background] transition-colors cursor-pointer 
    min-w-[8rem] max-h-[25rem] w-full h-full snap-center flex flex-col" @click="acitve = true">
    <!-- cover -->
    <div class="rounded-xl shadow-xl bg-[#333333] overflow-hidden grid place-items-center aspect-square" :class="{
      'series-cover': acitve,
    }">
      <img :src="series.cover || '/images/cover.png'" @error="e => { e.target.src = '/images/cover.png' }"
        class="object-center object-cover select-none h-full w-full" />
    </div>

    <!-- title -->
    <div class="mt-2 md:mt-4 md:min-h-[62px]">
      <div class="flex gap-1 font-mono text-zinc-400">
        <Square2StackIcon class="size-4 flex-none" />
        <p class="text-xs truncate">{{ series.code?.join(',') }}</p>
      </div>
      <p class="pb-1 text-sm md:text-base font-normal md:font-bold !text-white truncate">
        {{ translate(series, 'name') }}
      </p>
      <p class="text-xs text-sm font-mono text-zinc-400 truncate">{{ series.sellAt?.at(-1) || '-' }}</p>
    </div>
  </NuxtLink>
</template>

<script setup>
import { Square2StackIcon } from '@heroicons/vue/24/solid'
const { translate } = usePreferenceStore()
const { series } = defineProps({
  series: {
    type: Object,
    default: {},
  },
})

const acitve = ref(false)
</script>

<style scoped>
.series-cover {
  view-transition-name: series-cover;
}
</style>
